import moment from 'moment';
import userApi from '@/api/user';
import schoolApi from '@/api/school'
//import terminalApi from "@/api/terminal";
import deviceApi from "@/api/device";
import store from "@/store";

const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true'

//Command 정의
const CTRL_GW_ALLCONTROL = 222          //All Device Control (gradeName별로 제어 가능)
const CTRL_GW_AIRCON_MODETEMP = 223     //냉방기 모드/온도 변경
const CTRL_GW_CIRCULATOR_LEVEL = 224    //순환기 단계별 레벨 설정
const CTRL_GW_PURIFIER_LEVEL = 225      //청정기 단계별 레벨 설정
const CTRL_GW_LEDONOFF = 226            //클락 LED 전체 ON/OFF
const CTRL_GW_ALLCTRLBYKIND = 227       //디바이스 종류별로 전체제어

const DEVICE_CTRL_GATEWAY_CTRL = 150 //DEVICE_CONTROL subcmd for GATEWAY CONTROL
const DEVICE_CTRL_GATEWAY_CTRL_RESTART = 0 //앱 재시작
const DEVICE_CTRL_GATEWAY_CTRL_REBOOT = 1 //게이트웨이 리부트
const DEVICE_CTRL_GATEWAY_CTRL_SCREENCAP = 2 //화면캡쳐
const DEVICE_CTRL_GATEWAY_CTRL_LOGCAP = 3 //로그캡쳐
const DEVICE_CTRL_GATEWAY_CTRL_DELPUSH = 4 //푸시삭제
const DEVICE_CTRL_GATEWAY_CTRL_SETARS = 5 //ARS NO설정

const DISP_AIR_ONLY = 0;
const DISP_CMONITOR_ONLY = 1;
const DISP_AIR_CMONITOR_BOTH = 2;

const URL_PORTAL2_MISEWATCH = 0;
const URL_SHELTER_MISEWATCH = 1;
const URL_SUMTUR_MISEWATCH = 2;
const URL_KT_MISEWATCH = 3;
const URL_MY_SMART_BUS = 4;
const URL_PORTAL_SMART_BUS = 5;
const URL_PORTAL_SKINNSKIN = 6;
const URL_GANGSEO_SKINNSKIN = 7;
const URL_PORTAL_BUSSTOP = 8;
const URL_INCHEON_MISEWATCH = 9;
const URL_JNE_MISEWATCH = 10;

const common = {
    install(Vue) {

        Vue.prototype.$common = {};

        Vue.prototype.$common.CTRL_GW_ALLCONTROL = CTRL_GW_ALLCONTROL
        Vue.prototype.$common.CTRL_GW_AIRCON_MODETEMP = CTRL_GW_AIRCON_MODETEMP
        Vue.prototype.$common.CTRL_GW_CIRCULATOR_LEVEL = CTRL_GW_CIRCULATOR_LEVEL
        Vue.prototype.$common.CTRL_GW_PURIFIER_LEVEL = CTRL_GW_PURIFIER_LEVEL
        Vue.prototype.$common.CTRL_GW_LEDONOFF = CTRL_GW_LEDONOFF
        Vue.prototype.$common.CTRL_GW_ALLCTRLBYKIND = CTRL_GW_ALLCTRLBYKIND

        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL = DEVICE_CTRL_GATEWAY_CTRL
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_RESTART = DEVICE_CTRL_GATEWAY_CTRL_RESTART
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_REBOOT = DEVICE_CTRL_GATEWAY_CTRL_REBOOT
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_SCREENCAP = DEVICE_CTRL_GATEWAY_CTRL_SCREENCAP
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_LOGCAP = DEVICE_CTRL_GATEWAY_CTRL_LOGCAP
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_DELPUSH = DEVICE_CTRL_GATEWAY_CTRL_DELPUSH
        Vue.prototype.$common.DEVICE_CTRL_GATEWAY_CTRL_SETARS = DEVICE_CTRL_GATEWAY_CTRL_SETARS

        Vue.prototype.$common.DISP_AIR_ONLY = DISP_AIR_ONLY
        Vue.prototype.$common.DISP_CMONITOR_ONLY = DISP_CMONITOR_ONLY
        Vue.prototype.$common.DISP_AIR_CMONITOR_BOTH = DISP_AIR_CMONITOR_BOTH

        Vue.prototype.$common.URL_PORTAL2_MISEWATCH = URL_PORTAL2_MISEWATCH
        Vue.prototype.$common.URL_SHELTER_MISEWATCH = URL_SHELTER_MISEWATCH
        Vue.prototype.$common.URL_SUMTUR_MISEWATCH = URL_SUMTUR_MISEWATCH
        Vue.prototype.$common.URL_KT_MISEWATCH = URL_KT_MISEWATCH
        Vue.prototype.$common.URL_MY_SMART_BUS = URL_MY_SMART_BUS
        Vue.prototype.$common.URL_PORTAL_SMART_BUS = URL_PORTAL_SMART_BUS
        Vue.prototype.$common.URL_PORTAL_SKINNSKIN = URL_PORTAL_SKINNSKIN
        Vue.prototype.$common.URL_GANGSEO_SKINNSKIN = URL_GANGSEO_SKINNSKIN
        Vue.prototype.$common.URL_PORTAL_BUSSTOP = URL_PORTAL_BUSSTOP
        Vue.prototype.$common.URL_INCHEON_MISEWATCH = URL_INCHEON_MISEWATCH
        Vue.prototype.$common.URL_JNE_MISEWATCH = URL_JNE_MISEWATCH
        
        //common sub command
        Vue.prototype.$common.MW_SubCMD_REBOOT = 0       //0
        Vue.prototype.$common.MW_SubCMD_FACTORY          //1
        Vue.prototype.$common.MW_SubCMD_SET_DIS_MODE     //2
        Vue.prototype.$common.MW_SubCMD_BLEPAIR          //3
        Vue.prototype.$common.MW_SubCMD_CAL_CO2          //4
        Vue.prototype.$common.MW_SubCMD_SET_LORA_CH      //5
        Vue.prototype.$common.MW_SubCMD_SET_GROUP_ID     //6
        Vue.prototype.$common.MW_SubCMD_RS485_TEST       //7
        Vue.prototype.$common.MW_SubCMD_CTRL_LCDBACKLIGHT//8
        Vue.prototype.$common.MW_SubCMD_CTRL_LED         //9
        Vue.prototype.$common.MW_SubCMD_FACTORYMODE      //10 // add
        Vue.prototype.$common.MW_SubCMD_SLOPE            //11 pm2p5 slope
        Vue.prototype.$common.MW_SubCMD_KFACTOR          //12 pm2p5 kfactor
    
        //clock device control subcmd define
        Vue.prototype.$common.MW_SubCMD_CTRL_CLOCK = 50  //50 for clock wifi
        Vue.prototype.$common.MW_SubCMD_CLOCK_LVL = 51        //51 (0~255)
        Vue.prototype.$common.MW_SubCMD_MOOD_LVLCOLOR = 52    //52 (0~255, red, green, blue)
        Vue.prototype.$common.MW_SubCMD_CLOCK_TIMEFORMAT = 53 //53 (0: 1:25, 01:25, 13:25)
        Vue.prototype.$common.MW_SubCMD_CLOCK_DISPMODE = 54   //54 (0: timeonly, 1:time+temp/humi, 2:time+pm, 3:temp/humi only)
        Vue.prototype.$common.MW_SubCMD_INIT_DEVICE = 55      //55 (initialize device)
        Vue.prototype.$common.MW_SubCMD_CTRL_DEVICE = 56      //56 (control device)
        Vue.prototype.$common.MW_SubCMD_INTV_DEVICE = 57      //57 (device status send interval)
        Vue.prototype.$common.MW_SubCMD_FACTORY_TEST = 58     //58 (factory test)
        Vue.prototype.$common.MW_SubCMD_PM_DATA_DEVICE = 59   //59 (send pm data)
        Vue.prototype.$common.MW_SubCMD_CV_DATA_DEVICE = 60   //60 (send co2, voc data)
        Vue.prototype.$common.MW_SubCMD_THL_DATA_DEVICE = 61   //61 (send temp, humi, level data)
        Vue.prototype.$common.MW_SubCMD_TM_DATA_DEVICE = 62    //62 (send time data --> epoch MSB-epoch-epoch-epochLSB)
        Vue.prototype.$common.MW_SubCMD_CLOCK_LEDBRTBYTIME = 63 //63 (LED bright by time --> start time-end time-bright-XX)  
        Vue.prototype.$common.MW_SubCMD_CO2VOC_LED_CTRL = 64 //64 (CO2/VOC LED On/Off, Blink Control)  
        Vue.prototype.$common.MW_SubCMD_CLOCK_TIMER = 65 //65 (Clock Up/Down Timer)  
        Vue.prototype.$common.MW_SubCMD_CLOCK_DOT_COLORMODE = 66 //66 dot led on/off when normal(white) status
        Vue.prototype.$common.MW_SubCMD_INIT_DEVICE_BYGW = 67 //67 r4471 device init by clock pressing button 8 times.
        Vue.prototype.$common.MW_SubCMD_CLOCK_STATUS = 68 //68 clock status
        Vue.prototype.$common.MW_SubCMD_TIME_INTERVAL = 69 //69 POSTIN, GETOUT등 Interval을 분단위로 설정
        Vue.prototype.$common.MW_SubCMD_VENTIL_ALARM = 70 //70 vetilation alarm on/off from seoul
        
        Vue.prototype.$common.isMobile = function () {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
            } else {
                return false;
                //return true;
            }
        }

        Vue.prototype.$common.dispMode = function (authInfo) {
            if(authInfo.option) {
                return authInfo.option & 0xf;
            }
            return DISP_AIR_ONLY;
        }

        Vue.prototype.$common.setDispMode = function (option, dispMode) {
            return (option & 0xfffffff0) | dispMode;
        }
        
        /*
         title: 브라우저 탭에 표시되는 텍스트
        */
        Vue.prototype.$common.getUrl = function () {
            let url = window.location.toString();
            let ret = {url: 0, banner: "", title: "" };

            if(url.includes("shelter.misewatch.com")) {
                ret.url = URL_SHELTER_MISEWATCH;
                ret.banner = 'shelter';
                ret.title = '미세와치';
            } else if(url.includes("sumtur.misewatch.com")) {
                ret.url = URL_SUMTUR_MISEWATCH;
                ret.banner = 'sumtur';
                ret.title = '숨터';
            } else if(url.includes("my.smart-bus.co.kr")) {
                ret.url = URL_MY_SMART_BUS;
                ret.banner = 'shelter';
                ret.title = '스마트쉘터';
            } else if(url.includes("portal.smart-bus.co.kr")) {
                ret.url = URL_PORTAL_SMART_BUS;
                ret.banner = 'shelter';
                ret.title = '스마트쉘터';
            } else if(url.includes("portal.skinnskin.co.kr")) {
                ret.url = URL_PORTAL_SKINNSKIN;
                ret.banner = 'skinnskin';
                ret.title = '에어클린시스템즈';
            } else if(url.includes("gangseo.skinnskin.co.kr")) {
                ret.url = URL_GANGSEO_SKINNSKIN;
                ret.banner = 'gangseo';
                ret.title = '강서구청';
            } else if(url.includes("kt.misewatch.com")) {
                ret.url = URL_KT_MISEWATCH;
                ret.banner = 'kt';
                ret.title = '미세와치';
            } else if(url.includes("portal.busstop.co.kr")) {
                ret.url = URL_PORTAL_BUSSTOP;
                ret.banner = 'hanyang';
                ret.title = '스마트쉘터';
            } else if(url.includes("incheon.misewatch.com")) {
                ret.url = URL_INCHEON_MISEWATCH;
                ret.banner = 'school';
                ret.title = '미세와치';
            } else if(url.includes("jne.misewatch.com")) {
                ret.url = URL_JNE_MISEWATCH;
                ret.banner = 'jne';
                ret.title = '전남교육청';
            } else {
                ret.url = URL_PORTAL2_MISEWATCH;
                ret.banner = 'school';
                ret.title = '미세와치';
            }

            return ret;
        }
        
        Vue.prototype.$common.isShelter = function () {
            if(IS_MOCK) {
                return true;
            }

            let url = this.getUrl().url;
            return (url == URL_MY_SMART_BUS || url == URL_PORTAL_SMART_BUS || url == URL_SHELTER_MISEWATCH || url == URL_PORTAL_BUSSTOP);
        }

        //전문통계에서 10분자료 추출이 가능한지 체크.
        //현재는 스킨앤스킨만 지원하기로 한다.
        Vue.prototype.$common.isSupport10MinData = function () {
            let url = this.getUrl().url;
            return (url == URL_PORTAL_SKINNSKIN);
            //for test
            //return true;
        }

        Vue.prototype.$common.isAirKoreaDevice = function (mac) {
            if(mac && !isNaN(Number(mac.substring(1)))==true) {
                return true;
            }

            return false;
        }
        
        Vue.prototype.$common.modifyPhoneNumber = function (value) {
            
            //console.log("value="+value);

            if(!value || value == '') {
                return "";
            } else {
  
                let maxLen = 13;
                //let regex = new RegExp('[^0-9' + allowChr + ']', 'gi');
                //let ret = value.replace(regex, '');

                value = value.replace(/[^0-9]/g, "");

                //console.log("value2="+value);
        
                let result = [];
                let restNumber = "";
        
                // 지역번호와 나머지 번호로 나누기
                if (value.startsWith("0")) {
                    if(value.startsWith("00")) {
                        restNumber = "";
                    } else if(value.startsWith("02")) {
                        // 서울 02 지역번호
                        //02-341-4444, 02-3333-4444
                        result.push(value.substr(0, 2));
                        restNumber = value.substring(2);
                        maxLen = 12;
                    } else {
                        // 나머지 3자리 지역번호, 핸드폰
                        // 0xx-yyyy-zzzz
                        result.push(value.substr(0, 3));
                        restNumber = value.substring(3);
                        maxLen = 13;
                    }
                } else {
                    if (value.startsWith("1")) {
                        // 지역 번호가 없는 경우
                        // 1xxx-yyyy
                        restNumber = value;
                    } else {
                        restNumber = "";
                    }
                    maxLen = 9;
                }
        
                if(restNumber.length === 7) {
                    // 7자리만 남았을 때는 xxx-yyyy
                    result.push(restNumber.substring(0, 3));
                    result.push(restNumber.substring(3));
                } else {
                    result.push(restNumber.substring(0, 4));
                    result.push(restNumber.substring(4));
                }

                let modifyValue = result.filter((val) => val).join("-");
                if(modifyValue.length > maxLen) {
                    modifyValue = modifyValue.substring(0, maxLen);
                }

                return modifyValue;
            }
        }

        Vue.prototype.$common.isEmpty = function(param) {
            if(param == null) return true;
            
            return Object.keys(param).length === 0;
        }

        Vue.prototype.$common.hasKey = function(obj, key) {
            return Object.keys(obj).includes(key);
        }

        Vue.prototype.$common.convertJson = function(item) {
            let value = typeof item !== "string" ? JSON.stringify(item) : item;
            try {
              value = JSON.parse(value);
            } catch (e) {
              return {};
            }
      
            if(typeof value === "object" && value !== null) {
              return value;
            }
      
            return {};
        }

        /*
        Vue.prototype.$common.makeAccessList_ORG = function(accessClass, regionGrade) {
            let list = [];
            let gradeName = null;
            accessClass.forEach(g => {
                if(gradeName != g.gradeName) {
                    if(!regionGrade || (regionGrade.gradeName == g.gradeName)) {
                        list.push({gradeName:g.gradeName, className:g.className, classList:[{classNo:g.classNo, fullName:g.fullName}]});
                        gradeName = g.gradeName;
                    }
                } else {
                    let findGrade = list.find(e => e.gradeName == gradeName);
                    if(findGrade) {
                        findGrade.classList.push({classNo:g.classNo, fullName:g.fullName});
                    }
                }
            });

            return list;
        }
        */

        Vue.prototype.$common.makeAccessList = function(accessClass, regionGrade) {
            //console.log("regionGrade="+JSON.stringify(regionGrade));

            //regionGrade = null : 전체제어, 전체 AccessList
            //regionGrade != null
            //  gradeName key만 있으면 구역제어
            //  다른 grade관련 key가 모두 있는 경우 EachDeviceControl

            let list = [];
            let gradeName = null;
            accessClass.forEach(g => {
                if(gradeName != g.gradeName) {
                    if(!regionGrade || (!regionGrade.classNo && regionGrade.gradeName == g.gradeName) || (regionGrade.gradeName == g.gradeName && regionGrade.classNo == g.classNo)) {
                        list.push({gradeName:g.gradeName, className:g.className, classList:[{classNo:g.classNo, fullName:g.fullName}]});
                        gradeName = g.gradeName;
                    }
                } else {
                    let findGrade = list.find(e => e.gradeName == gradeName);
                    if(findGrade) {
                        findGrade.classList.push({classNo:g.classNo, fullName:g.fullName});
                    }
                }
            });

            return list;
        }

        Vue.prototype.$common.checkValidateSerial = function(serial) {
            if(serial == null || serial == '' || serial == 'g001122334455' || serial == 'g000000000000' || serial == 'g111111111111' || serial == 'g123456123456') {
              return false;
            }
      
            //console.log(`type=${type} serial=[${serial}] sub=${serial.substring(1)}`);
            let serial_type = serial.substring(0,1);
      
            if((serial_type != 'g' && serial_type != 'i' && serial_type != 'c' && serial_type != 's' && serial_type != 'o' && serial_type != 'w')) {
              return false;
            }
      
            let regEx = /^[a-f0-9]{12}$/;
            if(!regEx.test(serial.substring(1))) {
              return false;
            }
      
            return true;
        }

        //제어가능한 ClassList를 구성
        Vue.prototype.$common.getAccessList = async function(regionGrade) {
            
            let managerAccessList = [];

            let authInfo = store.getters['auth/authenticationUserInfo'];
            let uuid = store.getters['auth/getSchoolUUID'];
            
            //let schoolInfo = store.getters['school/schoolInfo'];
            //console.log("schoolInfo1="+JSON.stringify(schoolInfo));

            if(authInfo.authority == 0 || authInfo.authority == 10) {
                let param = {uuid:uuid};
                try{
                    let resp = await userApi.getManageUserList(param);
                    //console.log("managerList==="+JSON.stringify(resp));
                    if(resp && resp.list && resp.list.length > 0) {
                        
                        let data = await schoolApi.getSchool(uuid);
                        let grades = [];
                        if(data && data.class) {
                            grades = data.class;
                        }

                        //resp.list.forEach(user => {
                        for (let user of resp.list) {    
                            let option = this.convertJson(user.option);
                            if(option.gatewayMac && this.checkValidateSerial(option.gatewayMac) && option.accessClassList) {

                                let accessClass  = grades.filter(function(cls){
                                    return option.accessClassList.filter(function(classNo) {
                                        return cls.classType == 0 && cls.classNo == classNo;
                                    }).length != 0
                                });
                                
                                if(accessClass.length > 0) {
                                    let accessList = this.makeAccessList(accessClass, regionGrade);
                                    //console.log("accessList="+JSON.stringify(accessList));
                                    if(accessList.length > 0) {
                                        //load controlData from T_MWD_OPTION
                                        let resp = {};
                                        try {
                                            let param = {uuid: uuid, mac: option.gatewayMac};
                                            resp = await deviceApi.getControlData(param);
                                        } catch(err){
                                            //console.err("error.");
                                            console.log("option data not found.");
                                        }
                                        managerAccessList.push({name:user.name, id:user.id, gatewayMac:option.gatewayMac, accessList:accessList, controlData: this.convertJson(resp.controlData)});
                                    }
                                }
                            }
                        }
                    }
                } catch(err){
                    console.err("error.");
                }
            } else {
                let option = this.convertJson(authInfo.option);
                //console.log("option="+JSON.stringify(option));
                if(option.gatewayMac && this.checkValidateSerial(option.gatewayMac) && option.accessClassList) {
                    let grades = store.getters["school/gradeInfo"];
                    let accessClass  = grades.filter(function(cls){
                        return option.accessClassList.filter(function(classNo) {
                            return cls.classType == 0 && cls.classNo == classNo;
                        }).length != 0
                    });
                    //console.log("accessClass="+JSON.stringify(accessClass));
                    if(accessClass.length > 0) {
                        let accessList = this.makeAccessList(accessClass, regionGrade);
                        //console.log("accessList2="+JSON.stringify(accessList));
                        if(accessList.length > 0) {
                            //load controlData from T_MWD_OPTION
                            let resp = {};
                            try {
                                let param = {uuid: uuid, mac: option.gatewayMac};
                                resp = await deviceApi.getControlData(param);
                            } catch(err){
                                //console.err("error.");
                                console.log("option data not found2.");
                            }
                            managerAccessList.push({name:authInfo.name, id:authInfo.id, gatewayMac:option.gatewayMac, accessList:accessList, controlData: this.convertJson(resp.controlData)});
                        }
                    }
                }             
            }

            if(managerAccessList.length == 0) {
                //managerAccessList 0개이면 대표게이트웨이 MAC을 가져와서 선택된 것으로 설정한다.
                let schoolInfo = store.getters['school/schoolInfo'];
                //console.log("schoolInfo="+JSON.stringify(schoolInfo));

                if(this.checkValidateSerial(schoolInfo.gatewayMac)) {
                    managerAccessList = [{name:authInfo.name, id:authInfo.id, gatewayMac:schoolInfo.gatewayMac, accessList:[], controlData: this.convertJson(schoolInfo.controlData)}];
                }
            }
            
            /*
            if(managerAccessList.length == 1) {
                //managerAccessList 1개이면 선택된 것으로 간주한다.
                managerAccessList[0].checked = true;
            }
            */

            //for test
            if(IS_MOCK) {
                managerAccessList = [
                        {"name":"테스트담당자", "id":"testid", "gatewayMac":"gb75607001111","accessList":[{"gradeName":"1","className":"실내","classList":[{"classNo":2,"fullName":"대표이사실"}]},{"gradeName":"3","className":"개발실","classList":[{"classNo":692,"fullName":"제어(시작실)"}]}]},
                        //{"name":"개발테스터2", "id":"devtester2", "gatewayMac":"gb75607008696","accessList":[{"gradeName":"1","className":"실내","classList":[{"classNo":2,"fullName":"대표이사실"},{"classNo":4,"fullName":"WIFI테스트"},{"classNo":5,"fullName":"안정성테스트(시작실)"},{"classNo":6,"fullName":"OTA테스트"},{"classNo":96,"fullName":"CO2에러테스트"},{"classNo":143,"fullName":"영업용"}]}]},
                        //{"name":"개발테스터", "id":"devtester", "gatewayMac":"gb97142001333","accessList":[{"gradeName":"3","className":"개발실","classList":[{"classNo":692,"fullName":"제어(시작실)"},{"classNo":693,"fullName":"측정(시작실)"}]}]}
                    ];
            }

            if(authInfo.authority == 0) {
                console.log("managerAccessList="+JSON.stringify(managerAccessList));
            }

            return managerAccessList;
        }


        //제어가능한 ClassList를 구성
        // Vue.prototype.$common.getAccessList_ORG = async function(regionGrade) {
            
        //     //length == 0 --> 기존방식을 의미함.
        //     let managerAccessList = [];

        //     let authInfo = store.getters['auth/authenticationUserInfo'];
        //     let uuid = store.getters['auth/getSchoolUUID'];
        //     let schoolInfo = store.getters['school/schoolInfo'];

        //     console.log("schoolInfo="+JSON.stringify(schoolInfo));

        //     if(authInfo.authority == 0 || authInfo.authority == 10) {
        //         let param = {uuid:uuid};
        //         try{
        //             let resp = await userApi.getManageUserList(param);
        //             //console.log("resp==="+JSON.stringify(resp));
        //             if(resp && resp.list && resp.list.length > 0) {
        //                 //resp.list.forEach(user => {
        //                 for (let user of resp.list) {    
        //                     let option = this.convertJson(user.option);
        //                     if(option.gatewayMac && option.accessClassList) {
        //                         let data = await schoolApi.getSchool(uuid);
        //                         let grades = [];
        //                         if(data && data.class) {
        //                             grades = data.class;
        //                         }

        //                         let accessClass  = grades.filter(function(cls){
        //                             return option.accessClassList.filter(function(classNo) {
        //                                 return cls.classType == 0 && cls.classNo == classNo;
        //                             }).length != 0
        //                         });
                                
        //                         if(accessClass.length > 0) {
        //                             let accessList = this.makeAccessList(accessClass, regionGrade);
        //                             //console.log("accessList="+JSON.stringify(accessList));
        //                             if(accessList.length > 0 && this.checkValidateSerial(option.gatewayMac)) {
        //                                 managerAccessList.push({name:user.name, id:user.id, gatewayMac:option.gatewayMac, accessList:accessList});
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         } catch(err){
        //             console.err("error.");
        //         }
        //     } else {
        //         let option = this.convertJson(authInfo.option);
        //         //console.log("option="+JSON.stringify(option));
        //         if(option.gatewayMac && option.accessClassList) {
        //             let grades = store.getters["school/gradeInfo"];
        //             let accessClass  = grades.filter(function(cls){
        //                 return option.accessClassList.filter(function(classNo) {
        //                     return cls.classType == 0 && cls.classNo == classNo;
        //                 }).length != 0
        //             });
        //             //console.log("accessClass="+JSON.stringify(accessClass));
        //             if(accessClass.length > 0) {
        //                 let accessList = this.makeAccessList(accessClass, regionGrade);
        //                 //console.log("accessList2="+JSON.stringify(accessList));
        //                 if(accessList.length > 0 && this.checkValidateSerial(option.gatewayMac)) {
        //                     managerAccessList.push({name:authInfo.name, id:authInfo.id, gatewayMac:option.gatewayMac, accessList:accessList});
        //                 }
        //             }
        //         }             
        //     }

        //     if(managerAccessList.length == 0) {
        //         //managerAccessList 0개이면 대표게이트웨이 MAC을 가져와서 선택된 것으로 설정한다.
        //         let schoolInfo = store.getters['school/schoolInfo'];
        //         await terminalApi.getGatewayList(schoolInfo.name, null, false).then(resp=>{
        //             resp.list.forEach(elm => {
        //               if(elm.schoolUuid == schoolInfo.uuid && this.checkValidateSerial(elm.mwdMac)) {
        //                   managerAccessList = [{name:authInfo.name, id:authInfo.id, gatewayMac:elm.mwdMac, accessList:[]}];
        //               }
        //             });
        //         }).catch(err=>console.error(err));
        //     }
            
        //     /*
        //     if(managerAccessList.length == 1) {
        //         //managerAccessList 1개이면 선택된 것으로 간주한다.
        //         managerAccessList[0].checked = true;
        //     }
        //     */

        //     //for test
        //     if(IS_MOCK) {
        //         managerAccessList = [
        //                 {"name":"테스트담당자", "id":"testid", "gatewayMac":"gb75607001111","accessList":[{"gradeName":"1","className":"실내","classList":[{"classNo":2,"fullName":"대표이사실"}]},{"gradeName":"3","className":"개발실","classList":[{"classNo":692,"fullName":"제어(시작실)"}]}]},
        //                 {"name":"개발테스터2", "id":"devtester2", "gatewayMac":"gb75607008696","accessList":[{"gradeName":"1","className":"실내","classList":[{"classNo":2,"fullName":"대표이사실"},{"classNo":4,"fullName":"WIFI테스트"},{"classNo":5,"fullName":"안정성테스트(시작실)"},{"classNo":6,"fullName":"OTA테스트"},{"classNo":96,"fullName":"CO2에러테스트"},{"classNo":143,"fullName":"영업용"}]}]},
        //                 {"name":"개발테스터", "id":"devtester", "gatewayMac":"gb97142001333","accessList":[{"gradeName":"3","className":"개발실","classList":[{"classNo":692,"fullName":"제어(시작실)"},{"classNo":693,"fullName":"측정(시작실)"}]}]}
        //             ];
        //     }

        //     console.log("managerAccessList="+JSON.stringify(managerAccessList));

        //     return managerAccessList;
        // }
    }
}

export default common;